$desktop-width: 991.98px;

#payment-grid {
    min-height: 100%;
    margin: 0;
    @media (max-width: $desktop-width) {
        height: unset;
    }

    #table-column {
        padding-right: 5%;
    }
    
    #line-item-table {
        thead {
            font-size: 12px !important;
            th {
                padding-top: 8px !important;
                padding-bottom: 8px !important;
            }
        }
    
        tbody {
            font-size: 16px !important;
            tr {
                border-bottom: 0 !important;
                border-top: 1px solid rgba(0,0,0,.1) !important;
            }
            td {
                padding-top: 24px !important;
                padding-bottom: 24px !important;
            }
        }
    
        tfoot {
            td {
                font-size: 14px !important;
                border: 0 !important;
                &.total-details {
                    font-size: 16px !important;
                }
            }
            .total td {
                font-size: 18px !important;
                font-weight: 700;
                border-top: 0 !important;
            }
        }
    }
    
    #payment-column {
        font-size: 16px;
        @media (max-width: $desktop-width) {
            padding: 14px 18px !important;
        }

        #payment-form {
            @media (max-width: $desktop-width) {
                width: 100% !important;
                padding: 25px !important;
            }
        }
    }
}