@media (max-width: 767.98px) {
  #root {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 576.98px) {
  .requirements-collaps {
    background: none;
  }
  .requirements-collaps main > header {
    height: 0;
    overflow: hidden;
    transition: 0.3s all;
  }
  .mobile-no-Overflow {
    overflow: hidden;
    position: relative;
  }

  .mobile-no-Overflow::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: #fff;
    height: 200vh;
    width: 100%;
    z-index: 999;
  }

  header.wrapper {
    padding: 0 12px;
  }
  .card {
    margin-top: 12px;
    margin-bottom: 0px;
    border-radius: 0;
  }
  .card section {
    padding: 16px;
  }
  .inp-checkbox {
    padding-left: 8px;
    padding-right: 8px;
  }
  .inp-checkbox label {
    padding-left: 20px;
  }
  .inp-checkbox .child {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 999999;
}
.loader-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
.loader-content h2 {
  margin: 24px 0 0;
  opacity: 0.75;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
}
.loader-icon ellipse {
  animation: loader-anination 0.8s infinite linear;
}
.loader-icon ellipse:nth-child(2) {
  animation-delay: -0.7s;
}
.loader-icon ellipse:nth-child(3) {
  animation-delay: -0.6s;
}
.loader-icon ellipse:nth-child(4) {
  animation-delay: -0.5s;
}
.loader-icon ellipse:nth-child(5) {
  animation-delay: -0.4s;
}
.loader-icon ellipse:nth-child(6) {
  animation-delay: -0.3s;
}
.loader-icon ellipse:nth-child(7) {
  animation-delay: -0.2s;
}
.loader-icon ellipse:nth-child(8) {
  animation-delay: -0.1s;
}
@-webkit-keyframes loader-anination {
  75% {
    fill: transparent;
  }
  100% {
    fill: transparent;
  }
}
@keyframes loader-anination {
  75% {
    fill: transparent;
  }
  100% {
    fill: transparent;
  }
}

* {
  font-family: 'Mulish' !important;
}

/* STRIPE CSS */
#root {
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 300px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-complete {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}